export const brand = process.env.GATSBY_BRAND;
export const isBe = brand === 'be';
export const isRf = brand === 'rf';

if (!isBe && !isRf) throw new Error(`Unrecognized brand '${brand}'`);

// images
//

export const img = (relPathAndFile: string, pageOrComponent?: string) => {
   return imgLogic(req, relPathAndFile, pageOrComponent);
};

export const imgBrand = (relPathAndFile: string, pageOrComponent?: string) => {
   return imgLogic(reqBranded, relPathAndFile, pageOrComponent);
};

const imgLogic = (
   f: (prefix: string, relPathAndFile: string) => string,
   relPathAndFile: string,
   pageOrComponent?: string
) => {
   if (pageOrComponent) {
      return f('l', `${pageOrComponent}/${relPathAndFile}`);
   } else return f('g', relPathAndFile);
};
const reqBranded = (prefix: string, relPathAndFile: string) => {
   return req(`${brand}/${prefix}`, relPathAndFile);
};

const req = (prefix: string, relPathAndFile: string) => {
   return require(`/src/images/${prefix}/${relPathAndFile}`);
};

// less / css
//
export const primaryColor = isBe ? '#FF8A00' : '#C82021';

// logo
//
export const brandLogoUuid = isBe ? 'b2aaa0cb-d242-48d7-ac87-6cc18f55d6ba' : 'ae2c212e-a4c5-47b1-b88f-68b3fbf08a5c';
export const brandLogo200 = `https://ucarecdn.com/${brandLogoUuid}/-/preview/200x200/be-circle-mark.png`;
export const brandLogo = `https://ucarecdn.com/${brandLogoUuid}/-/preview/be-circle-mark.png`;

// URL
//
export const domain = isBe ? 'bayequityhomeloans' : 'redfinmortgage';
export const brandUrl = `https://${domain}.com`;
export const brandUrlWww = `https://www.${domain}.com`;
export const selfLink = `<a href="${brandUrl}">www.${domain}.com</a>`;

// SEO
//
export const siteNameEnglish = isBe ? 'Bay Equity Home Loans' : 'Redfin Mortgage'; /*todo*/
export const siteNameShort = isBe ? 'Bay Equity' : 'Redfin'; /*todo*/
export const altSuffix = ` | ${siteNameEnglish}`;
export const alt = (s: string) => `${s}${altSuffix}`;

// Google Maps
export const mapPopupQuery = (address: string[]) =>
   isBe ? `bay%20equity,%20${address}` : `redfin%20mortgage,%20${address}`; /*todo*/

// Meta
export const metaLoanOfficer = (name: string, title: string) =>
   `Hi, my name is ${name} and I’m a ${title} at ${siteNameEnglish}.${isBe ? ' We’re a full-service mortgage lender and it’s my job to get you home.' : ''}`; /*todo*/
export const metaBranch = (title: string) =>
   `Welcome to ${siteNameShort} ${title}.${isBe ? ' We are part of Bay Equity, a full-service mortgage lender that’s here to get you home.' : ''}`; /*todo*/
export const metaTeam = (title: string) =>
   `Hi, we’re the ${title}, a team of home loan professionals at ${siteNameEnglish}.${isBe ? 'We’re a full-service mortgage lender that’s here to get you home.' : ''}`; /*todo*/

// Theme
export const getPageTheme = () => {
   const result = isBe ? 'blue-ribbon' : 'default';
   return result;
};

// Misc
export const brandSlogan = isBe ? `We’re Here To Get You Home` : `We’re Here To Get You Home`; /*todo*/
export const defaultMapBranch = isBe
   ? {
        title: 'Bay Equity Home Loans',
        address1: '28 Liberty Ship Way',
        address2: 'Ste 2800',
        address3: '',
        city: 'Sausalito',
        state: 'CA',
        zip: '94965',
        lat: 37.8623892,
        lon: -122.4942879
     }
   : {
        title: 'Bay Equity Home Loans',
        address1: '28 Liberty Ship Way',
        address2: 'Ste 2800',
        address3: '',
        city: 'Sausalito',
        state: 'CA',
        zip: '94965',
        lat: 37.8623892,
        lon: -122.4942879
     }; /*todo*/
